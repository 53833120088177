import React from "react"
import { graphql, Link } from "gatsby"
import { PrismicText, PrismicRichText } from "@prismicio/react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import Layout from "../../components/layout/Layout"
import Newsletter from "../../components/layout/Newsletter"

const PrismicPost = ({ data }) => {
  // For previews prismic is using the prismicPost query
  const doc = Object.hasOwn(data, "prismicPost")
    ? data.prismicPost.data
    : data.allPrismicPost.edges.slice(0, 1).pop().node.data
  if (!doc) return null

  return (
    <Layout
      smoothScrolling={false}
      title={doc.meta_title}
      description={doc.meta_description}
      image={doc.hero_image.url}
    >
      <div>
        <div className="mt-10 mx-auto max-w-4xl px-4 sm:mt-12 sm:px-6 md:mt-16">
          <Link to="/blog">
            <span>← Zurück zum Blog</span>
          </Link>
          <section id="header" className="text-center">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-none font-semibold">
              <PrismicText field={doc.title.richText} />
            </h1>
            <div className="my-6 flex justify-center">
              <img
                className="h-10 w-10 rounded-full"
                src={doc.meta_author_image.url}
                alt={doc.meta_author_image.alt}
              />
              <div className="ml-3">
                <p className="text-sm leading-5 font-medium text-gray-900 text-left">
                  {doc.meta_author.text}
                </p>
                <div className="flex text-sm leading-5 text-gray-600">
                  <time>{doc.date}</time>
                  <span className="mx-1">&middot;</span>
                  <span>{doc.article_length.text}</span>
                </div>
              </div>
            </div>
            <div className="my-6">
              <img
                className="md:h-96 w-full object-cover"
                src={doc.hero_image.url}
                alt=""
              />
            </div>
          </section>
          <section id="post-pody">
            <PrismicRichText field={doc.post_body.richText} />
          </section>
        </div>
      </div>
      <Newsletter></Newsletter>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($uid: String) {
    allPrismicPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          _previewable
          data {
            title {
              richText
            }
            date
            post_body {
              richText
            }
            hero_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            meta_title
            meta_description
            meta_author {
              text
            }
            meta_author_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            article_length {
              text
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(PrismicPost)
