import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-4 px-20 text-lg`
};

class Button extends React.Component {

  componentDidMount() {
    var ctaButton =  document.getElementById('cta-button');
    if (typeof(ctaButton) != 'undefined' && ctaButton != null) {
      ctaButton.setAttribute("href", this.props.link + window.location.search);
    }
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <a href={this.props.link} id={this.props.buttonid}>        
        <button
          type="button"
          className={`
            ${sizes[this.props.size] || sizes.default}
            ${this.props.className}
            bg-primary
            hover:bg-primary-hover
            rounded
            text-white
        `}
        >
          {this.props.children}
        </button>
      </a>
    );
  }
}

export default Button
