exports.linkResolver = function linkResolver(doc) {
  // Route for blog posts
  if (doc.type === "post") {
    return "/blog/" + doc.uid
  }
  if (doc.type === "industry_page") {
    return "/industry/" + doc.uid
  }
  if (doc.type === "funnel_page") {
    return "/pages/" + doc.uid
  }
  if (doc.type === "demo_page") {
    return "/demo/" + doc.uid
  }
  if (doc.type === "wizard") {
    return "/wizard/" + doc.uid
  }
  if (doc.type === "homepage") {
    return doc.lang === "de-de" ? "/" : "/en"
  }
  if (doc.type === "pricing") {
    return doc.lang === "de-de" ? "/pricing" : "/en/pricing"
  }
  if (doc.type === "careers_page") {
    return doc.lang === "de-de" ? "/careers" : "/en/careers"
  }
  if (doc.type === "customer_service_page") {
    return doc.lang === "de-de"
      ? "/solutions/customer-service"
      : "/en/solutions/customer-service"
  }
  // Homepage route fallback
  return "/"
}
