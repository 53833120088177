import React from "react"

import UserCentrics from "../UserCentrics"

import Footer from "./Footer"
import Header from "./Header"
import SEO from "./SEO"

function Layout({
  children,
  smoothScrolling = false,
  showUsercentrics = true,
  showNaviButtons = true,
  language = "de",
  title = "Chatchamp | Conversational Commerce Solution",
  description = "Nutze Chatchamps digitale Produktberater (Chatbots) um auf deiner Website deine Kunden ideal beraten zu können. Jederzeit, ohne Mehraufwand. Erhöhe somit deinen Umsatz und Conversionrate.",
  image,
}) {
  console.log(description)
  return (
    <>
      <SEO title={title} description={description} image={image} />
      {showUsercentrics && <UserCentrics />}
      <Header
        smoothScrolling={smoothScrolling}
        language={language}
        showNaviButtons={showNaviButtons}
      />
      <main className="text-gray-900">{children}</main>
      <Footer language={language} />
    </>
  )
}

export default Layout
