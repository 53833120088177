import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import chatchampLogo from "../../svg/chatchamp-logo.svg"
import Button from "../Button"
import DropdownNavigationElement from "./DropdownNavigationElement"
import { headerData } from "./headerData"

const Header = ({
  smoothScrolling = false,
  language = "de",
  showNaviButtons = true,
}) => {
  const {
    logoLinkUrl,
    solutionsLabel,
    solutionOptions,
    featuresText,
    featuresUrl,
    featuresAnchorUrl,
    customersText,
    customersUrl,
    customersAnchorUrl,
    pricingText,
    pricingUrl,
    languageText,
    languageUrl,
    tryNowText,
    tryNowUrl,
  } = headerData[language]
  return (
    <header className="header-navigation sticky top-0 bg-white shadow">
      <div className="container flex flex-col md:flex-row justify-between items-center mx-auto py-3 px-8">
        <div className="flex items-center text-2xl">
          <div className="w-40 mr-3">
            <a href={logoLinkUrl}>
              <img src={chatchampLogo} />
            </a>
          </div>
        </div>
        {showNaviButtons === true ? (
          <div className="flex space-x-4 md:space-x-8 mt-4 md:mt-0">
            <DropdownNavigationElement
              label={solutionsLabel}
              options={solutionOptions}
            />
            {smoothScrolling ? (
              <>
                <AnchorLink offset={80} href={featuresAnchorUrl}>
                  {featuresText}
                </AnchorLink>
                <AnchorLink
                  offset={80}
                  className="hidden sm:block"
                  href={customersAnchorUrl}
                >
                  {customersText}
                </AnchorLink>
              </>
            ) : (
              <>
                <a href={featuresUrl}>{featuresText}</a>
                <a href={customersUrl} className="hidden sm:block">
                  {customersText}
                </a>
              </>
            )}
            <a href={pricingUrl}>{pricingText}</a>
            <a href={languageUrl} className="shrink-0">
              {languageText}
            </a>
          </div>
        ) : (
          <div className="flex mt-4 md:mt-0 px-4"></div>
        )}
        {showNaviButtons === true ? (
          <div className="hidden lg:block">
            <a href="https://app.chatchamp.com/login" className="px-4">
              Login
            </a>
            <Button className="text-sm" link={tryNowUrl}>
              {tryNowText}
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </header>
  )
}

export default Header
