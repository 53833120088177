import * as React from "react"
import "./src/css/tailwind.css"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import PrismicPost from "./src/pages/blog/{PrismicPost.uid}.js"

const { linkResolver } = require("./src/utils/linkResolver")

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: `chatchamp-homepage`,
        linkResolver: doc => linkResolver(doc),
        componentResolver: componentResolverFromMap({ post: PrismicPost }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)
