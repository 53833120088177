import React from "react"

const Newsletter = () => (
  <div className="bg-white">
    <div className="max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
      <div className="px-6 py-6 bg-primary rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div className="xl:w-0 xl:flex-1">
          <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
            Neugierig geworden? Du willst zum Thema Conversational Commerce auf
            dem Laufenden bleiben?
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-indigo-200">
            Melde dich jetzt zu unserem Newsletter an.
          </p>
        </div>
        <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          {/* Begin Mailchimp Signup Form */}
          <div id="mc_embed_signup">
            <form
              action="https://chatchamp.us15.list-manage.com/subscribe/post?u=baa75388e283fdafd07d668d7&id=e698f4513a"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="sm:flex validate"
              target="_blank"
              noValidate
            >
              <div className="flex" id="mc_embed_signup_scroll">
                <input
                  aria-label="Email address"
                  id="mce-EMAIL"
                  name="EMAIL"
                  type="email"
                  required
                  className="required email appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:placeholder-gray-500 transition duration-150 ease-in-out"
                  placeholder="Tippe deine E-Mail ein"
                />
                <div className="px-4">
                  <div id="mce-responses">
                    <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: "none" }}
                    />
                    <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: "none" }}
                    />
                  </div>{" "}
                  {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_baa75388e283fdafd07d668d7_e698f4513a"
                      tabIndex={-1}
                      defaultValue
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      defaultValue="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button px-8 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/*End mc_embed_signup*/}

          <p className="mt-3 text-sm leading-5 text-indigo-200">
            Wir kümmern uns um Datenschutz. Lies gerne unsere&nbsp;
            <a href="/privacy" className="text-white font-medium underline">
              Datenschutzerklärung.
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Newsletter
