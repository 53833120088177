import React from 'react';

class UserCentrics extends React.Component {
  src = 'https://app.usercentrics.eu/browser-ui/latest/bundle.js'

  componentDidMount() {
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute('src', this.src)
    script.setAttribute('defer', true)
    script.setAttribute('data-settings-id', '0KerReed2')
    script.setAttribute('id', 'usercentrics-cmp')
    head.appendChild(script)
  }

  componentWillUnmount() {
    const head = document.querySelector('head')
    const script = document.querySelector('script')
    head.removeChild(script)
  }

  render() {
    return (
      <div></div>
    )
  }
}

export default UserCentrics
