import {
  ChatBubbleLeftRightIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline"

const headerDataDE = {
  logoLinkUrl: "/",
  solutionsLabel: "Lösungen",
  solutionOptions: [
    {
      name: "Customer Service AI",
      description:
        "Chatchamps Customer Service AI Chatbot, der deinen Kundenservice revolutioniert & automatisiert.",
      href: "/solutions/customer-service",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: "Guided Selling",
      description:
        "Führe mit Chatchamps digitalem Produktberater deine Kunden zum idealen Produkt und erhöhe somit deinen Umsatz.",
      href: "/",
      icon: ShoppingCartIcon,
    },
  ],
  featuresText: "Features",
  featuresUrl: "/#features",
  featuresAnchorUrl: "#features",
  customersText: "Kunden",
  customersUrl: "/#testimonials",
  customersAnchorUrl: "#testimonials",
  pricingText: "Preispakete",
  pricingUrl: "/pricing",
  languageText: "EN 🇺🇸",
  languageUrl: "/en",
  tryNowText: "Jetzt testen",
  tryNowUrl: "http://contact.chatchamp.com/testzugang",
}

const headerDataEN = {
  logoLinkUrl: "/en",
  solutionsLabel: "Solutions",
  solutionOptions: [
    {
      name: "Customer Service AI",
      description:
        "Discover Chatchamps Customer Service AI Chatbot, revolutionizing and elevating your customer service journey.",
      href: "/en/solutions/customer-service",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: "Guided Selling",
      description:
        "Discover Chatchamps Product Advisor, a game-changing tool that assists your customers in finding their ideal products effortlessly.",
      href: "/en",
      icon: ShoppingCartIcon,
    },
  ],
  featuresText: "Features",
  featuresUrl: "/en/#features",
  featuresAnchorUrl: "#features",
  customersText: "Customer",
  customersUrl: "/en/#testimonials",
  customersAnchorUrl: "#testimonials",
  pricingText: "Pricing",
  pricingUrl: "/en/pricing",
  languageText: "DE 🇩🇪",
  languageUrl: "/",
  tryNowText: "Try now",
  tryNowUrl: "https://contact.chatchamp.com/demo-access",
}

export const headerData = {
  de: headerDataDE,
  en: headerDataEN,
}
